import React, { useEffect, useState } from "react";
import HeaderComponent from "../../components/headerComponent/headerComponent";
import styles from "./engagementPage.module.css";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  ThemeProvider,
  createTheme,
  styled,
} from "@mui/material";
import {
  addDataEngagements,
  deleteDataEngagements,
  getDataDestinataires,
  getDataEngagements,
  getDataAllEngagements,
  getDataEngagementsDocs,
  getDataOperationElpev,
  updateDataEngagements,
} from "../../apiService";
import { getApiUrl } from "../../config";
import {
  DataGrid,
  GridColDef,
  GridEventListener,
  // GridRowEditStopReasons,
  frFR,
} from "@mui/x-data-grid";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import DeleteIcon from "@mui/icons-material/Delete";

export default function EngagementPage() {
  const theme = createTheme(
    {
      palette: {
        primary: { main: "#1976d2" },
      },
    },
    frFR
  );

  const columnss: GridColDef[] = [
    {
      field: "id",
      headerName: "",
      headerClassName: styles.headerBgColor,
      width: 50,
      renderHeader: (params) => (
        <div style={{ fontWeight: "bold" }}>{params.colDef.headerName}</div>
      ),
      cellClassName: styles.bold,

      renderCell: (params) => (
        <div>
          <DeleteIcon
            // onClick={() => deleteEngagement(params.value)}
            onClick={() => {
              handleDeleteModalOpen();
              setEngToDelete(params.value);
            }}
            // style={{ color: "white", backgroundColor: "#d32f2f" }}
          />
        </div>
      ),
    },
    {
      field: "Nom",
      headerName: "Nom",
      headerClassName: styles.headerBgColor,
      width: 230,
      renderHeader: (params) => (
        <div style={{ fontWeight: "bold" }}>{params.colDef.headerName}</div>
      ),
      cellClassName: styles.bold,
    },
    {
      field: "NomSoc",
      headerName: "Entrepot",
      headerClassName: styles.headerBgColor,
      width: 230,
      renderHeader: (params) => (
        <div style={{ fontWeight: "bold" }}>{params.colDef.headerName}</div>
      ),
    },
    {
      field: "NomDocument",
      headerName: "Nom document",
      headerClassName: styles.headerBgColor,
      width: 230,
      renderHeader: (params) => (
        <div style={{ fontWeight: "bold" }}>{params.colDef.headerName}</div>
      ),
    },
    {
      field: "QuantiteApproximative",
      headerName: "Quantité approximative",
      headerClassName: styles.headerBgColor,
      width: 230,
      renderHeader: (params) => (
        <div style={{ fontWeight: "bold" }}>{params.colDef.headerName}</div>
      ),
      editable: true,
    },
    {
      field: "QuantiteDefinitive",
      headerName: "Quantité définitive",
      headerClassName: styles.headerBgColor,
      width: 230,
      renderHeader: (params) => (
        <div style={{ fontWeight: "bold" }}>{params.colDef.headerName}</div>
      ),
      editable: true,
    },
  ];

  const [rowss, setRowss] = React.useState([]);
  const [apiUrl, setApiUrl] = useState("");
  const [noOp, setnoOp] = useState<any>(null);
  const [op, setOp] = useState<any>(null);
  const [quantityFilter, setQuantityFilter] = useState<any>(null);
  const [documents, setDocuments] = useState<any>([]);
  const [destinataires, setDestinataires] = useState<any>([]);
  const [destinataire, setDestinataire] = useState<any>(null);
  const [engagementDocs, setEngagementDocs] = useState<any>([]);
  const [engagementDoc, setEngagementDoc] = useState<any>(null);
  const [quantiteApproximative, setQuantiteApproximative] = useState<any>("");
  const [quantiteDefinitive, setQuantiteDefinitive] = useState<any>("");
  const [open, setOpen] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [selectionDone, setSelectionDone] = React.useState<any>(false);
  const [notifOpen, setNotifOpen] = React.useState(false);
  const [engToDelete, setEngToDelete] = useState<any>(null);

  const quantityOptions = [
    { Libelle: "TOUTES", label: "TOUTES", id: undefined, Id: 0 },
    { Libelle: "SAISIES", label: "SAISIES", id: undefined, Id: 1 },
    { Libelle: "À SAISIR", label: "À SAISIR", id: undefined, Id: 2 },
  ];

  const handleClickOpen = () => {
    getDestinataires();
    getEngagementsDocs();
    setOpen(true);
  };

  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleNotifClick = () => {
    setNotifOpen(true);
  };

  const handleNotifClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setNotifOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
  };

  async function checkQuantites() {
    if (!quantiteApproximative || quantiteApproximative === "") {
      await setQuantiteApproximative(0);
    }
    if (!quantiteDefinitive || quantiteDefinitive === "") {
      await setQuantiteDefinitive(0);
    }
  }

  function filterOp(ops: any) {
    if (quantityFilter) {
      
    switch (quantityFilter.Id) {
      case 0:
        return ops;
        // break;
      case 1:
        return ops.filter(
          (op: any) =>
            op.QuantiteDefinitive !== 0 && op.QuantiteDefinitive !== null
        );
        // break;
      case 2:
        return ops.filter(
          (op: any) =>
            op.QuantiteDefinitive === 0 && op.QuantiteDefinitive !== null
        );
        // break;

      case null:
        return ops;
        // break;
    }
    } else {
      return ops;
    }
  }

  const getData = async (): Promise<void> => {
    const data = await getDataOperationElpev();
    const AllOpFilter = {
      Libelle: "TOUTES",
      Id: 0,
    };
    if (data) {
      data.unshift(AllOpFilter);
      data.forEach(
        (element: { label: any; Libelle: any; id: any; ID: any }) => {
          element.id = element.ID;
          element.label = element.Libelle;
        }
      );
      setRowss(data);
    } else {
      setRowss([]);
    }
  };

  function selectEngagement() {
    if (op.Libelle === "TOUTES") {
      getAllEngagements();
    } else {
      getEngagements();
    }
  }

  const getEngagements = async (): Promise<void> => {
    var data = await getDataEngagements(noOp);

    if (data) {
      data = filterOp(data);
      data.forEach((element: { NoEngagement: any; id: any }) => {
        element.id = element.NoEngagement;
      });
      setDocuments(data);
    } else {
      setDocuments([]);
    }
    setSelectionDone(true);
  };

  const getAllEngagements = async (): Promise<void> => {
    var data = await getDataAllEngagements();

    if (data) {
      data = filterOp(data);
      data.forEach((element: { NoEngagement: any; id: any }) => {
        element.id = element.NoEngagement;
      });
      setDocuments(data);
    } else {
      setDocuments([]);
    }
    setSelectionDone(true);
  };

  const addEngagements = async (): Promise<void> => {
    await checkQuantites();
    const data = await addDataEngagements(
      engagementDoc.NoDocument,
      destinataire.NoDestinataire,
      quantiteApproximative,
      quantiteDefinitive
    );

    if (data) {
      if (data.message) {
        handleNotifClick();
        setOpen(false);
        getEngagements();
      }
    } else {
    }
  };

  function beforeUpdateEngagements(data: any) {
    updateEngagements(
      data.NoEngagement,
      data.QuantiteApproximative,
      data.QuantiteDefinitive
    );
  }

  const updateEngagements = async (
    noEng: any,
    qtta: any,
    qttd: any
  ): Promise<void> => {
    await checkQuantites();
    const data = await updateDataEngagements(noEng, qtta, qttd);

    if (data) {
      if (data.message) {
        // handleNotifClick();
        // setOpen(false);
        getEngagements();
      }
    } else {
    }
  };

  const deleteEngagement = async (): Promise<void> => {
    const data = await deleteDataEngagements(engToDelete);

    if (data) {
      if (data.message) {
        getEngagements();
      }
    } else {
    }
    setDeleteModal(false);
  };

  const getDestinataires = async (): Promise<void> => {
    const data = await getDataDestinataires();

    if (data) {
      data.forEach(
        (element: { label: any; Nom: any; NoEngagement: any; id: any }) => {
          element.id = element.NoEngagement;
          element.label = element.Nom;
        }
      );
      setDestinataires(data);
    } else {
      setDestinataires([]);
    }
  };

  const getEngagementsDocs = async (): Promise<void> => {
    const data = await getDataEngagementsDocs(noOp);

    if (data) {
      var i = 1;
      data.forEach(
        (element: {
          label: any;
          NomDocument: any;
          NoEngagement: any;
          id: any;
        }) => {
          if (element.NomDocument) {
            element.id = element.NoEngagement;
            element.label = element.NomDocument;
          } else {
            element.NomDocument = `sans titre ${i}`;
            element.label = `sans titre ${i}`;
            i++;
          }
        }
      );

      setEngagementDocs(data);
    } else {
      setEngagementDocs([]);
    }
  };

  const handleAutocompleteChange = (
    event: React.ChangeEvent<{}>,
    newValue: any
  ) => {
    if (newValue == null) {
      setnoOp(null);
      setOp(null);
      setSelectionDone(null);
    } else {
      setnoOp(newValue.NoOperation);
      setOp(newValue);
    }
  };

  const handleQuantityAutocompleteChange = (
    event: React.ChangeEvent<{}>,
    newValue: any
  ) => {
    if (newValue == null) {
      setQuantityFilter(null);
    } else {
      setQuantityFilter(newValue);
    }
  };

  const handleDestinataireChange = (
    event: React.ChangeEvent<{}>,
    newValue: any
  ) => {
    setDestinataire(newValue);
    checkQuantites();
  };

  const handleDocumentChange = (
    event: React.ChangeEvent<{}>,
    newValue: any
  ) => {
    setEngagementDoc(newValue);
    checkQuantites();
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    // if (params.reason === GridRowEditStopReasons.rowFocusOut) {
    //   event.defaultMuiPrevented = true;
    // }
    //     const editedRowId = params.id;
    // // Pour mettre fin à l'édition de la ligne, utilisez la référence API
    // apiRef.current.stopRowEditMode({ id: editedRowId });
  };

  const handleProcessRowUpdateError = React.useCallback((error: Error) => {
    console.log(error);
  }, []);

  useEffect(() => {
    getApiUrl()
      .then(async (apiUrl) => {
        if (apiUrl) {
          await setApiUrl(apiUrl);
          getData();
        }
      })
      .catch((error) => {
        // Gérez l'erreur ici si nécessaire
        console.error(
          "Erreur lors de la récupération de l'URL de l'API :",
          error
        );
      });
  }, [apiUrl]);

  return (
    <div className={styles.mainPage}>
      <HeaderComponent />
      <div className={styles.pageContainer}>
        <div className={styles.mainContainer}>
          {/* Titre */}
          <div className={styles.title}>
            <div className={styles.bold}>Vos engagements</div>
          </div>
          <div
            className={`${styles.bold} ${styles.fontColor} ${styles.mBottom}`}
          >
            Sélectionner une opération
          </div>
          <div className={styles.buttonStack}>
            <Stack spacing={2} direction="row" className={styles.mBottom}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={rowss}
                onChange={handleAutocompleteChange}
                value={op}
                sx={{ width: 450 }}
                renderInput={(params) => (
                  <TextField {...params} label="Operation(s)" />
                )}
              />
              <Autocomplete
                disablePortal
                id="combo-box-quantity"
                options={quantityOptions}
                onChange={handleQuantityAutocompleteChange}
                value={quantityFilter}
                sx={{ width: 150 }}
                renderInput={(params) => (
                  <TextField {...params} label="Quantité(s)" />
                )}
              />
              <Button
                variant="contained"
                size="large"
                style={{ height: 56, fontSize: 20 }}
                onClick={selectEngagement}
                disabled={!op}
              >
                Go !
              </Button>
            </Stack>
            <Button
              variant="contained"
              size="large"
              color="success"
              style={{ height: 56, fontSize: 18, textTransform: "none" }}
              onClick={handleClickOpen}
              disabled={!noOp || !selectionDone}
            >
              Ajouter un engagement
            </Button>
          </div>
          <div>
            <ThemeProvider theme={theme}>
              <DataGrid
                style={{ width: "100%" }}
                rows={documents}
                columns={columnss}
                className={`${styles.withRows} ${
                  documents.length > 0 ? styles.withRows : styles.withoutRows
                }`}
                disableRowSelectionOnClick
                editMode="row"
                processRowUpdate={(updatedRow, originalRow) =>
                  beforeUpdateEngagements(updatedRow)
                }
                onProcessRowUpdateError={handleProcessRowUpdateError}
                onRowEditStop={handleRowEditStop}
                slots={{
                  noRowsOverlay: CustomNoRowsOverlay,
                }}
                getRowId={(row) => row.id}
              />
            </ThemeProvider>
          </div>
        </div>
      </div>
      {/* Dialog / modal */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="alert-dialog-title" className={styles.modalTitle}>
          {"Ajouter un engagement"}
        </DialogTitle>
        <DialogContent className={styles.modalComponent}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={destinataires}
            onChange={handleDestinataireChange}
            value={destinataire}
            sx={{ width: 400 }}
            className={styles.modalItems}
            renderInput={(params) => (
              <TextField {...params} label="Destinataire" />
            )}
          />
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={engagementDocs}
            onChange={handleDocumentChange}
            value={engagementDoc}
            sx={{ width: 400 }}
            className={styles.modalItems}
            renderInput={(params) => <TextField {...params} label="Document" />}
          />
          <TextField
            id="outlined-basic"
            label="Quantité approximative"
            variant="outlined"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setQuantiteApproximative(event.target.value);
              // checkQuantites();
            }}
            value={quantiteApproximative}
            type="number"
            sx={{ width: 400 }}
            className={styles.modalItems}
          />
          <TextField
            id="outlined-basic"
            label="Quantité définitive"
            variant="outlined"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setQuantiteDefinitive(event.target.value);
              // checkQuantites();
            }}
            value={quantiteDefinitive}
            type="number"
            sx={{ width: 400 }}
            className={styles.modalItems}
          />
          <Button
            variant="contained"
            size="large"
            color="success"
            style={{ height: 46, fontSize: 16 }}
            onClick={addEngagements}
            className={styles.modalItems}
            disabled={!destinataire || !engagementDoc}
          >
            Ajouter
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog open={deleteModal} onClose={handleDeleteModalClose}>
        <DialogTitle id="alert-dialog-title" className={styles.modalTitle}>
          {"Voulez-vous vraiment supprimer cet engagement ?"}
        </DialogTitle>
        <DialogContent className={styles.modalComponent}>
          <Button
            variant="contained"
            size="large"
            color="info"
            style={{ height: 46, fontSize: 16 }}
            onClick={deleteEngagement}
            className={styles.modalItems}
          >
            Supprimer
          </Button>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={notifOpen}
        autoHideDuration={6000}
        onClose={handleNotifClose}
      >
        <MuiAlert
          onClose={handleNotifClose}
          severity="info"
          sx={{ width: "100%" }}
        >
          Ressource ajoutée avec succès !
        </MuiAlert>
      </Snackbar>
    </div>
  );
}

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>Pas de résultats</Box>
    </StyledGridOverlay>
  );
}
