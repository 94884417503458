import { decodeCreds, getNoSoc } from "./appService";
import { encode as base64_encode } from "base-64";
import { getApiUrl } from "./config";

const annee = new Date().getFullYear();
var apiUrl: any;

getApiUrl()
  .then(async (ApiUrl) => {
    if (ApiUrl) {
      apiUrl = ApiUrl;
      // getDataOperationElpev();
    }
  })
  .catch((error) => {
    // Gérez l'erreur ici si nécessaire
    console.error("Erreur lors de la récupération de l'URL de l'API :", error);
  });

const getDataOperationElpev = async function () {
  try {
    var noSoc = getNoSoc();
    var ids = decodeCreds();
    var username = ids.un;
    var password = ids.pw;
    const encodedCredentials = base64_encode(`${username}:${password}`);

    const response = await fetch(`${apiUrl}/get-op?nocli=${noSoc}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${encodedCredentials}`,
      },
    });

    if (!response.ok) {
      // Si la réponse n'est pas OK (200-299), générer une erreur avec le statut de la réponse
      throw new Error(
        `Erreur HTTP: ${response.status} - ${response.statusText}`
      );
    }

    let data = await response.json();
    if (!data || data === null) {
      data = [];
    }    
    return data;
  } catch (error: any) {
    // Capturer l'erreur et la gérer ici
    console.error(
      "Une erreur s'est produite lors de la récupération des données:",
      error.message
    );
    // Vous pouvez également effectuer d'autres actions ici, par exemple afficher un message d'erreur à l'utilisateur.
  }
};

const getDataEngagements = async function (noOp: any) {
  try {
    var ids = decodeCreds();
    var username = ids.un;
    var password = ids.pw;
    const encodedCredentials = base64_encode(`${username}:${password}`);

    const response = await fetch(`${apiUrl}/get-engagements?noop=${noOp}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${encodedCredentials}`,
      },
    });

    if (!response.ok) {
      // Si la réponse n'est pas OK (200-299), générer une erreur avec le statut de la réponse
      throw new Error(
        `Erreur HTTP: ${response.status} - ${response.statusText}`
      );
    }

    let data = await response.json();
    if (!data || data === null) {
      data = [];
    }    return data;
  } catch (error: any) {
    // Capturer l'erreur et la gérer ici
    console.error(
      "Une erreur s'est produite lors de la récupération des données:",
      error.message
    );
    // Vous pouvez également effectuer d'autres actions ici, par exemple afficher un message d'erreur à l'utilisateur.
  }
};

const getDataAllEngagements = async function () {
  try {
    var noCli = getNoSoc();
    var ids = decodeCreds();
    var username = ids.un;
    var password = ids.pw;
    const encodedCredentials = base64_encode(`${username}:${password}`);

    const response = await fetch(`${apiUrl}/get-all-engagements?nocli=${noCli}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${encodedCredentials}`,
      },
    });

    if (!response.ok) {
      // Si la réponse n'est pas OK (200-299), générer une erreur avec le statut de la réponse
      throw new Error(
        `Erreur HTTP: ${response.status} - ${response.statusText}`
      );
    }

    let data = await response.json();
    if (!data || data === null) {
      data = [];
    }    return data;
  } catch (error: any) {
    // Capturer l'erreur et la gérer ici
    console.error(
      "Une erreur s'est produite lors de la récupération des données:",
      error.message
    );
    // Vous pouvez également effectuer d'autres actions ici, par exemple afficher un message d'erreur à l'utilisateur.
  }
};

const addDataEngagements = async function (noDoc: any, noDes: any, qttAppr: any, qttDef: any) {
  try {
    var ids = decodeCreds();
    var username = ids.un;
    var password = ids.pw;
    const encodedCredentials = base64_encode(`${username}:${password}`);

    const response = await fetch(`${apiUrl}/add-engagement?noDoc=${noDoc}&noDes=${noDes}&qttAppr=${qttAppr}&qttDef=${qttDef}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${encodedCredentials}`,
      },
    });

    if (!response.ok) {
      // Si la réponse n'est pas OK (200-299), générer une erreur avec le statut de la réponse
      throw new Error(
        `Erreur HTTP: ${response.status} - ${response.statusText}`
      );
    }

    let data = await response.json();
    if (!data || data === null) {
      data = [];
    }    return data;
  } catch (error: any) {
    // Capturer l'erreur et la gérer ici
    console.error(
      "Une erreur s'est produite lors de la récupération des données:",
      error.message
    );
    // Vous pouvez également effectuer d'autres actions ici, par exemple afficher un message d'erreur à l'utilisateur.
  }
};

const deleteDataEngagements = async function (noEng: any) {
  try {
    var ids = decodeCreds();
    var username = ids.un;
    var password = ids.pw;
    const encodedCredentials = base64_encode(`${username}:${password}`);

    const response = await fetch(`${apiUrl}/delete-engagement?noEngagement=${noEng}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${encodedCredentials}`,
      },
    });

    if (!response.ok) {
      // Si la réponse n'est pas OK (200-299), générer une erreur avec le statut de la réponse
      throw new Error(
        `Erreur HTTP: ${response.status} - ${response.statusText}`
      );
    }

    let data = await response.json();
    if (!data || data === null) {
      data = [];
    }    return data;
  } catch (error: any) {
    // Capturer l'erreur et la gérer ici
    console.error(
      "Une erreur s'est produite lors de la récupération des données:",
      error.message
    );
    // Vous pouvez également effectuer d'autres actions ici, par exemple afficher un message d'erreur à l'utilisateur.
  }
};

const updateDataEngagements = async function (noEng: any, qttAppr: any, qttDef: any) {
  try {
    var ids = decodeCreds();
    var username = ids.un;
    var password = ids.pw;
    const encodedCredentials = base64_encode(`${username}:${password}`);

    const response = await fetch(`${apiUrl}/update-engagement?noEng=${noEng}&qttAppr=${qttAppr}&qttDef=${qttDef}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${encodedCredentials}`,
      },
    });

    if (!response.ok) {
      // Si la réponse n'est pas OK (200-299), générer une erreur avec le statut de la réponse
      throw new Error(
        `Erreur HTTP: ${response.status} - ${response.statusText}`
      );
    }

    let data = await response.json();
    if (!data || data === null) {
      data = [];
    }    return data;
  } catch (error: any) {
    // Capturer l'erreur et la gérer ici
    console.error(
      "Une erreur s'est produite lors de la récupération des données:",
      error.message
    );
    // Vous pouvez également effectuer d'autres actions ici, par exemple afficher un message d'erreur à l'utilisateur.
  }
};

const getDataDestinataires = async function () {
  try {
    var noSoc = getNoSoc();
    var ids = decodeCreds();
    var username = ids.un;
    var password = ids.pw;
    const encodedCredentials = base64_encode(`${username}:${password}`);

    const response = await fetch(`${apiUrl}/get-destinataires?nocli=${noSoc}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${encodedCredentials}`,
      },
    });

    if (!response.ok) {
      // Si la réponse n'est pas OK (200-299), générer une erreur avec le statut de la réponse
      throw new Error(
        `Erreur HTTP: ${response.status} - ${response.statusText}`
      );
    }

    let data = await response.json();
    if (!data || data === null) {
      data = [];
    }    return data;
  } catch (error: any) {
    // Capturer l'erreur et la gérer ici
    console.error(
      "Une erreur s'est produite lors de la récupération des données:",
      error.message
    );
    // Vous pouvez également effectuer d'autres actions ici, par exemple afficher un message d'erreur à l'utilisateur.
  }
};

const getDataEngagementsDocs = async function (noOp: any) {
  try {
    var ids = decodeCreds();
    var username = ids.un;
    var password = ids.pw;
    const encodedCredentials = base64_encode(`${username}:${password}`);

    const response = await fetch(`${apiUrl}/get-engagements-docs?noop=${noOp}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${encodedCredentials}`,
      },
    });

    if (!response.ok) {
      // Si la réponse n'est pas OK (200-299), générer une erreur avec le statut de la réponse
      throw new Error(
        `Erreur HTTP: ${response.status} - ${response.statusText}`
      );
    }

    let data = await response.json();
    if (!data || data === null) {
      data = [];
    }    return data;
  } catch (error: any) {
    // Capturer l'erreur et la gérer ici
    console.error(
      "Une erreur s'est produite lors de la récupération des données:",
      error.message
    );
    // Vous pouvez également effectuer d'autres actions ici, par exemple afficher un message d'erreur à l'utilisateur.
  }
};

const getDataOperations = async function () {
  try {
    var noSoc = getNoSoc();
    var ids = decodeCreds();
    var username = ids.un;
    var password = ids.pw;
    const encodedCredentials = base64_encode(`${username}:${password}`);

    const from = `${annee}-01-01T00:00:00`;
    const to = `${annee}-12-31T23:59:59`;

    const response = await fetch(
      `${apiUrl}/filter-op?nocli=${noSoc}&from=${from}&to=${to}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${encodedCredentials}`,
        },
      }
    );

    if (!response.ok) {
      // Si la réponse n'est pas OK (200-299), générer une erreur avec le statut de la réponse
      throw new Error(
        `Erreur HTTP: ${response.status} - ${response.statusText}`
      );
    }

    let data = await response.json();
    if (!data || data === null) {
      data = [];
    }
    return data;
  } catch (error: any) {
    // Capturer l'erreur et la gérer ici
    console.error(
      "Une erreur s'est produite lors de la récupération des données:",
      error.message
    );
    // Vous pouvez également effectuer d'autres actions ici, par exemple afficher un message d'erreur à l'utilisateur.
  }
};

const getDataOperationsFilter = async function (filter: any, recherche: any) {
  try {
    var noSoc = getNoSoc();
    var ids = decodeCreds();
    var username = ids.un;
    var password = ids.pw;
    const encodedCredentials = base64_encode(`${username}:${password}`);

    const response = await fetch(
      `${apiUrl}/filter-op?nocli=${noSoc}&from=${filter.From}&to=${filter.To}&nomop=${recherche}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${encodedCredentials}`,
        },
      }
    );

    if (!response.ok) {
      // Si la réponse n'est pas OK (200-299), générer une erreur avec le statut de la réponse
      throw new Error(
        `Erreur HTTP: ${response.status} - ${response.statusText}`
      );
    }

    let data = await response.json();
    if (!data || data === null) {
      data = [];
    }    return data;
  } catch (error: any) {
    // Capturer l'erreur et la gérer ici
    console.error(
      "Une erreur s'est produite lors de la récupération des données:",
      error.message
    );
    // Vous pouvez également effectuer d'autres actions ici, par exemple afficher un message d'erreur à l'utilisateur.
  }
};

const getDataOperationsFilterByDoc = async function (filter: any, rechercheOp: any, rechercheDoc: any) {
  try {
    var noSoc = getNoSoc();
    var ids = decodeCreds();
    var username = ids.un;
    var password = ids.pw;
    const encodedCredentials = base64_encode(`${username}:${password}`);

    const response = await fetch(
      `${apiUrl}/filter-op-by-doc?nocli=${noSoc}&from=${filter.From}&to=${filter.To}&nomop=${rechercheOp}&nomdoc=${rechercheDoc}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${encodedCredentials}`,
        },
      }
    );

    if (!response.ok) {
      // Si la réponse n'est pas OK (200-299), générer une erreur avec le statut de la réponse
      throw new Error(
        `Erreur HTTP: ${response.status} - ${response.statusText}`
      );
    }

    let data = await response.json();
    if (!data || data === null) {
      data = [];
    }    return data;
  } catch (error: any) {
    // Capturer l'erreur et la gérer ici
    console.error(
      "Une erreur s'est produite lors de la récupération des données:",
      error.message
    );
    // Vous pouvez également effectuer d'autres actions ici, par exemple afficher un message d'erreur à l'utilisateur.
  }
};

const getDataDetailsTechniques = async function (noDevis: any, noDeviscom: any, noRef: any) {
  try {
    var ids = decodeCreds();
    var username = ids.un;
    var password = ids.pw;
    const encodedCredentials = base64_encode(`${username}:${password}`);

    const response = await fetch(
      `${apiUrl}/getDetailsTechniques?nodevis=${noDevis}&nodeviscom=${noDeviscom}&noref=${noRef}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${encodedCredentials}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error(
        `Erreur HTTP: ${response.status} - ${response.statusText}`
      );
    }
    const data = await response.json();
    return data[0];
  } catch (error: any) {
    console.error(
      "Une erreur s'est produite lors de la récupération des données:",
      error.message
    );
  }
};

export {
  getDataOperationElpev,
  getDataEngagements,
  getDataAllEngagements,
  addDataEngagements,
  updateDataEngagements,
  deleteDataEngagements,
  getDataDestinataires,
  getDataEngagementsDocs,
  getDataOperations,
  getDataOperationsFilter,
  getDataOperationsFilterByDoc,
  getDataDetailsTechniques,
};

//Exemple à modifier
