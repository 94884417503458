import React from "react";
import "./App.css";
import Loginpage from "./pages/loginPage/loginPage";
import MainPage from "./pages/mainPage/mainPage";
import EngagementPage from "./pages/engagementPage/engagementPage";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

function Home() {
  return <h2>Accueil</h2>;
}

function About() {
  return <h2>À propos</h2>;
}

// function PrivateRoute() {
//   const isAuthenticated = localStorage.getItem('user') !== null;

//   if (!isAuthenticated) {
//     return <Navigate to="/login" />;
//   }
//   return <Outlet />;
// }

// Check if user is authenticated => allow access to routes
// function PrivateRoute({children} : {children: React.ReactNode}) {
//   const isAuthenticated = localStorage.getItem('user') !== null;

//   if (!isAuthenticated) {
//     console.log("log in please");
    
//     return <Navigate to="/login" />;
//   }
//   console.log("welcome");
  
//   return <div>{children}</div>;
// }

function PrivateRoute({children} : {children: React.ReactNode}) {
  const userStr = localStorage.getItem('user');
  if (!userStr){
    return <Navigate to="/login" />;
  }

  var user = JSON.parse(userStr);
  user = user.user;
  const now = new Date();
  
  if (!user.expiry) {
    localStorage.removeItem("user");
    localStorage.removeItem("cacheData.token");
    return <Navigate to="/login" />;
  }

  if (now.getTime() > user.expiry) {
    localStorage.removeItem("user");
    localStorage.removeItem("cacheData.token");
    return <Navigate to="/login" />;
  }
  
  return <div>{children}</div>;
}

function App() {
  return (
    <Router>
        <Routes>
          <Route path="/" element={<PrivateRoute><MainPage /></PrivateRoute>} />
          <Route path="/engagements" element={<PrivateRoute><EngagementPage /></PrivateRoute>} />
          <Route path="/login" element={<Loginpage />} />
          
          <Route path="/home" element={<PrivateRoute><Home /></PrivateRoute>} />
          <Route path="/about" element={<PrivateRoute><About /></PrivateRoute>} />
        </Routes>
    </Router>
  );
}

export default App;
