import React from "react";
import styles from "./detailsComponent.module.css";
import dayjs from "dayjs";

// import BusinessIcon from "@mui/icons-material/Business";
// import LocalShippingIcon from "@mui/icons-material/LocalShipping";
// import DescriptionIcon from "@mui/icons-material/Description";
import CancelIcon from "@mui/icons-material/Cancel";
// import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
// import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
// import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
// import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import CloseIcon from "@mui/icons-material/Close";

import { Button } from "@mui/material";

interface DetailsProps {
  myObject: any;
  onDelete: () => void;
}

const DetailsComponent: React.FC<DetailsProps> = ({ myObject, onDelete }) => {
  var validite = `Validité : ${dayjs(myObject.ValiditeDu).format(
    "DD/MM/YYYY"
  )} → ${dayjs(myObject.ValiditeAu).format("DD/MM/YYYY")}`;

  if (typeof myObject.MontantCmdHT === "number") {
    myObject.MontantCmdHT = myObject.MontantCmdHT.toFixed(2);
  }
  if (typeof myObject.TotalPA_Marge === "number") {
    myObject.TotalPA_Marge = myObject.TotalPA_Marge.toFixed(2);
  }
  if (typeof myObject.CU === "number") {
    myObject.CU = myObject.CU.toFixed(2);
  }
  if (typeof myObject.Facture === "number") {
    myObject.Facture = myObject.Facture.toFixed(2);
  }
  if (typeof myObject.CU_Facture === "number") {
    myObject.CU_Facture = myObject.CU_Facture.toFixed(2);
  }

  // const order = myObject.OrderDetails;

  return (
    <div className={styles.container}>
      <div className={styles.table}>
        <div className={styles.tableHeader}>
          <div className={styles.title}>{myObject.Libelle}</div>
          <div>
            {validite}
            {/* Validité :{dayjs(myObject.ValiditeDu).format("DD/MM/YYYY")}
            {dayjs(myObject.ValiditeAu).format("DD/MM/YYYY")} */}
          </div>
          <Button className={styles.white} onClick={onDelete}>
            <CancelIcon />
          </Button>
        </div>
        <div className={styles.tableContent}>
          <div className={styles.tableRow}>
            <div className={styles.tableCell}>Semestre</div>
            <div className={styles.tableCell}>{myObject.Semestre}</div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.tableCell}> N° Reference</div>
            <div className={styles.tableCell}>{myObject.NoReference}</div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.tableCell}>Pagination</div>
            <div className={styles.tableCell}>{myObject.Pagination}</div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.tableCell}>Tirage</div>
            <div className={styles.tableCell}>{myObject.Tirage}</div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.tableCell}>Largeur</div>
            <div className={styles.tableCell}>
              {" "}
              {myObject.Largeur.toFixed(2)}
            </div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.tableCell}>Hauteur</div>
            <div className={styles.tableCell}>
              {" "}
              {myObject.Hauteur.toFixed(2)}
            </div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.tableCell}>Qualité</div>
            <div className={styles.tableCell}>{myObject.Finition}</div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.tableCell}>Grammage</div>
            <div className={styles.tableCell}>{myObject.Grammage}</div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.tableCell}>Façonnage</div>
            <div className={styles.tableCell}>{myObject.Faconnage}</div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.tableCell}>Budget</div>
            <div className={styles.tableCell}>
              {myObject.TotalPA_Marge}€ → {myObject.CU}€/u
            </div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.tableCell}>Facturé</div>
            <div className={styles.tableCell}>
              {myObject.Facture}€ → {myObject.CU_Facture}€/u
            </div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.tableCell}>Budget</div>
            <div className={styles.tableCell}>
              <CloseIcon style={{ color: "red" }} />
            </div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.tableCell}>Fiche planning</div>
            <div className={styles.tableCell}>
              <CloseIcon style={{ color: "red" }} />
            </div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.tableCell}>Fiche technique</div>
            <div className={styles.tableCell}>
              <CloseIcon style={{ color: "red" }} />
            </div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.tableCell}>Facture</div>
            <div className={styles.tableCell}>
              <CloseIcon style={{ color: "red" }} />
            </div>
          </div>
        </div>
      </div>
      {/* 2eme table  */}
      {/* <div className={styles.tableAlt}>
        <div className={styles.tableHeaderAlt}>COMMANDES IMPRESSIONS</div>
        <div className={styles.tableContent}>
          <div className={styles.tableRowAlt}>
            <BusinessIcon className={styles.icons} />
            IMPRIMEUR : {myObject.NomSoc}
          </div>
        </div>
      </div> */}
      {/* 3eme table si commande existe */}
      {/* {order && (
        <div className={styles.tableAlt}>
          <div className={styles.tableHeaderAlt}>COMMANDES PAPIER</div>
          <div className={styles.tableContent}>
            <div className={styles.tableRowAlt}>
              <BusinessIcon className={styles.icons} />
              Papetier : {order.NomSoc}
            </div>
            <div className={styles.tableRowAlt}>
              <StickyNote2OutlinedIcon className={styles.icons} />
              N° commande : {order.NoComm}
            </div>
            <div className={styles.tableRowAlt}>
              <LocalShippingOutlinedIcon className={styles.icons} />
              Date livraison : {dayjs(order.DateLivraison).format("DD/MM/YYYY")}
            </div>
            <div className={styles.tableRowAlt}>
              <DescriptionOutlinedIcon className={styles.icons} />
              Papier : {order.DefPapier} {order.Grammage}g
            </div>
            <div className={styles.tableRowAlt}>
              <DescriptionOutlinedIcon className={styles.icons} />
              Laize : {order.Laize}
            </div>
            <div className={styles.tableRowAlt}>
              <DescriptionOutlinedIcon className={styles.icons} />
              Tonnage : {order.TonnageCommande}Kg
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default DetailsComponent;
