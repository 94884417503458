import base64 from "base-64";

function getNoSoc() {
  var user = localStorage.getItem("user") ?? "";
  var parsedUser = JSON.parse(user);
  var noSoc = parsedUser.user.soc_id;
  return noSoc;
}

function decodeCreds() {
  var creds = localStorage.getItem("cacheData.token") ?? "";
  creds = base64.decode(creds);
  var tt = JSON.parse(creds);
  var ids = {
    un: base64.decode(tt.tokenBase),
    pw: base64.decode(tt.TokenPayload),
  };
  return ids;
}

export {getNoSoc, decodeCreds}