export function getApiUrl() {
    return fetch("/config.json") // Assurez-vous que le chemin d'accès soit correct
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); // Convertit la réponse en JSON
      })
      .then((data) => {
        // Maintenant, "data" contient le contenu du fichier JSON
        const apiUrl = data.apiUrl;
  
        // Retournez l'URL de l'API dans une promesse
        return apiUrl;
      })
      .catch((error) => {
        console.error(
          "Il y a eu une erreur lors de la récupération du fichier JSON:",
          error
        );
        throw error; // Propagez l'erreur pour qu'elle puisse être gérée ailleurs si nécessaire
      });
  }
  
// Lors du run avec docker, passer en volume un ficher de configuration config.json
// avec un propriété "apiUrl" pour définir l'url utilisée par l'application (qui elle va chercher dans le repertoire app/src/config.json)
// exemple : docker run -v "C:\Users\François AMPION\Downloads\config.json:/srv/benetton/www/config.json" -p 80:80 -d united
