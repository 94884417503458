// "use client";
import styles from "./loginPage.module.css";
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import base64 from "base-64";
import { getApiUrl } from "../../config";

export default function Loginpage() {
  const [apiUrl, setApiUrl] = useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const navigate = useNavigate();

  const login = async (username: string, password: string): Promise<void> => {
    try {
      const credentials = { Username: username, Password: password };
      const response = await fetch(`${apiUrl}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(credentials),
      });

      if (!response.ok) {
        // Si la réponse n'est pas OK (200-299), générer une erreur avec le statut de la réponse
        throw new Error(
          `Erreur HTTP: ${response.status} - ${response.statusText}`
        );
      }

      const data = await response.json();

      if (response.status === 200 || response.status === 201) {
        const now = new Date();
        // now + expiry time in ms => 4h
        data.user.expiry = now.getTime() + 1000 * 60 * 60 * 4;
        localStorage.setItem("user", JSON.stringify(data));

        var creds = {
          tokenBase: base64.encode(username),
          TokenPayload: base64.encode(password),
        };
        var ava = base64.encode(JSON.stringify(creds));
        localStorage.setItem("cacheData.token", ava);
        navigate("/");
      }
    } catch (error: any) {
      // Capturer l'erreur et la gérer ici
      console.error(
        "Une erreur s'est produite lors de la connexion:",
        error.message
      );
      // Vous pouvez également effectuer d'autres actions ici, par exemple afficher un message d'erreur à l'utilisateur.
    }
  };

  function handleLogin() {
    login(username, password);
  }

  useEffect(() => {
    getApiUrl()
      .then(async (apiUrl) => {
        if (apiUrl) {
          await setApiUrl(apiUrl);
        }
      })
      .catch((error) => {
        // Gérez l'erreur ici si nécessaire
        console.error(
          "Erreur lors de la récupération de l'URL de l'API :",
          error
        );
      });
  }, [apiUrl]);

  return (
    <div className={styles.imageBg} style={{backgroundImage: `url("/bgConnectScreen.jpg")`}}>
      <div className={styles.verticalCenter}>
        <div className={styles.container}>
          <img
            src="/OnePacLogo.png"
            alt="UNIted"
            style={{width : 180}}
          />
          <h1 className={styles.title}>Print management solution</h1>
          {/* <input className="field" type="text" /> */}
          <TextField
            className={`${styles.field} ${styles.fullWidth}`}
            id="username"
            label="Nom d'utilisateur"
            variant="outlined"
            value={username}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setUsername(event.target.value);
            }}
          />
          {/* <TextField
            className="field fullWidth"
            id="password"
            label="Mot de passe"
            type="password"
            variant="outlined"
          /> */}
          <FormControl
            className={`${styles.field} ${styles.fullWidth}`}
            sx={{ m: 1, width: "25ch" }}
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Mot de passe
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setPassword(event.target.value);
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
          {/* <FormControlLabel control={<Checkbox defaultChecked />} label="Label" /> */}
          <div className={`${styles.field} ${styles.formOptions}`}>
            <FormControlLabel
              control={<Switch defaultChecked />}
              label="Se souvenir de moi"
            />
            <a className={styles.forgotPassword} href="/">
              Mot de passe oublié ?
            </a>
          </div>
          <Button
            className={`${styles.field} ${styles.submitButton}`}
            variant="contained"
            onClick={handleLogin}
          >
            Bienvenue
          </Button>
        </div>
      </div>
    </div>
  );
}
