import React, { useEffect, useState } from "react";
import styles from "./headerComponent.module.css";
import "../../globals.css"
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import base64, { encode as base64_encode } from "base-64";
import { getApiUrl } from "../../config";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";

export default function HeaderComponent() {
  const [apiUrl, setApiUrl] = useState("");
  const navigate = useNavigate();
  const [identity, setIdentity] = useState<any>(null);
  const [logo, setLogo] = useState<any>(null);

  function logOut() {
    localStorage.removeItem("user");
    localStorage.removeItem("cacheData.token");
    navigate("/login");
  }

  function getCurrentUser() {
    var tempUser = JSON.parse(localStorage.getItem("user") ?? "");
    tempUser = tempUser.user;
    setIdentity(`${tempUser.soc}, ${tempUser.civility} ${tempUser.name}`);
  }

  function getNoSoc() {
    var user = localStorage.getItem("user") ?? "";
    var parsedUser = JSON.parse(user);
    var noSoc = parsedUser.user.soc_id;
    return noSoc;
  }

  function decodeCreds() {
    var creds = localStorage.getItem("cacheData.token") ?? "";
    creds = base64.decode(creds);
    var tt = JSON.parse(creds);
    var ids = {
      un: base64.decode(tt.tokenBase),
      pw: base64.decode(tt.TokenPayload),
    };
    return ids;
  }

  const getLogo = async (): Promise<void> => {
    try {
      var noSoc = getNoSoc();
      var ids = decodeCreds();
      var username = ids.un;
      var password = ids.pw;
      const encodedCredentials = base64_encode(`${username}:${password}`);

      const response = await fetch(
        `${apiUrl}/documents/logo?filename=${noSoc}.jpg`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${encodedCredentials}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(
          `Erreur HTTP: ${response.status} - ${response.statusText}`
        );
      }

      const imageBlob = await response.blob();
      const imageObjectURL = URL.createObjectURL(imageBlob);

      setLogo(imageObjectURL);
    } catch (error: any) {
      console.error(
        "Une erreur s'est produite lors de la récupération du logo:",
        error.message
      );
    }
  };

  useEffect(() => {
    getApiUrl()
      .then(async (apiUrl) => {
        if (apiUrl) {
          await setApiUrl(apiUrl);
          getCurrentUser();
          getLogo();
        }
      })
      .catch((error) => {
        // Gérez l'erreur ici si nécessaire
        console.error(
          "Erreur lors de la récupération de l'URL de l'API :",
          error
        );
      });
  }, [apiUrl]);

  return (
    <div>
      <div className={styles.navbar}>
        <div className={styles.brand}>
          <div className={styles.item}>
            <img src={logo} alt="logo de marque" />
          </div>
          <div className={styles.item}>
            <div className={styles.separator}></div>
          </div>
          <div className={styles.item}>
            <img
              style={{ padding: "8px" }}
              src="/OnePacLogo3.png"
              alt="logo united"
            />
          </div>
        </div>
        <div>
          <div className={`${styles.item} ${styles.column} ${styles.end}`}>
            <div>Vous êtes connecté en tant que</div>
            <div style={{ color: "blue" }}>{identity}</div>
            <Button style={{padding : 4}} className={styles.flexCenter} onClick={logOut}>
              <PowerSettingsNewIcon />
              <div>DÉCONNEXION</div>
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.menu}>
        <div className={styles.menuItem}>
          <SystemUpdateAltIcon className={styles.menuIcon} /> <Link to={"/"}>Vos opérations</Link>
        </div>
        <div className={styles.menuItem}>
          <SystemUpdateAltIcon className={styles.menuIcon} /> Demandez un devis
        </div>
        <div className={styles.menuItem}>
          <SystemUpdateAltIcon className={styles.menuIcon} />
          <Link to={"/engagements"}>Vos engagements</Link>
        </div>
      </div>
    </div>
  );
}
