import React, { useEffect, useState } from "react";
import styles from "./mainPage.module.css";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

import HeaderComponent from "../../components/headerComponent/headerComponent";
import DetailsComponent from "../../components/detailsComponent/detailsComponent";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CloseIcon from "@mui/icons-material/Close";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  DataGrid,
  GridColDef,
  frFR,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";

import dayjs from "dayjs";
import { getApiUrl } from "../../config";
import { TextField } from "@mui/material";
import {
  getDataDetailsTechniques,
  getDataOperations,
  getDataOperationsFilter,
  getDataOperationsFilterByDoc,
} from "../../apiService";
import { red } from "@mui/material/colors";

export default function MainPage() {
  const theme = createTheme(
    {
      palette: {
        primary: { main: "#1976d2" },
      },
    },
    frFR
  );

  const columnss: GridColDef[] = [
    {
      field: "id",
      headerName: "",
      headerClassName: styles.headerBgColor,
      width: 100,
      renderHeader: (params) => (
        <div style={{ fontWeight: "bold" }}>{params.colDef.headerName}</div>
      ),
      cellClassName: styles.bold,

      renderCell: (params) => (
        <div>
          <Button
            variant="text"
            onClick={() => openModal(params.value)}
            style={{ color: "rgb(59, 59, 80)" }}
          >
            <OpenInFullIcon />
          </Button>
        </div>
      ),
    },
    {
      field: "Libelle",
      headerName: "Opération",
      headerClassName: styles.headerBgColor,
      width: 230,
      renderHeader: (params) => (
        <div style={{ fontWeight: "bold" }}>{params.colDef.headerName}</div>
      ),
      cellClassName: styles.bold,
    },
    {
      field: "NomDocument",
      headerName: "Support",
      headerClassName: styles.headerBgColor,
      width: 230,
      renderHeader: (params) => (
        <div style={{ fontWeight: "bold" }}>{params.colDef.headerName}</div>
      ),
      cellClassName: styles.bold,
    },
    {
      field: "Validite",
      headerName: "Validité",
      headerClassName: styles.headerBgColor,
      width: 230,
      renderHeader: (params) => (
        <div style={{ fontWeight: "bold" }}>{params.colDef.headerName}</div>
      ),
      valueGetter: (params) => {
        return `${params.row.ValiditeDu || ""} → ${
          params.row.ValiditeAu || ""
        }`;
      },
      renderCell: (params) => {
        const [validiteDu, validiteAu] = params.value.split(" → ");
        return `${dayjs(validiteDu).format("DD/MM/YYYY")} → ${dayjs(
          validiteAu
        ).format("DD/MM/YYYY")}`;
      },
    },
    {
      field: "Semestre",
      headerName: "Semestre",
      headerClassName: styles.headerBgColor,
      width: 110,
      renderHeader: (params) => (
        <div style={{ fontWeight: "bold" }}>{params.colDef.headerName}</div>
      ),
    },
    {
      field: "DateLimiteCommande",
      headerName: "Date Limite Commande",
      headerClassName: styles.headerBgColor,
      width: 200,
      renderHeader: (params) => (
        <div style={{ fontWeight: "bold" }}>{params.colDef.headerName}</div>
      ),
      valueGetter: (params) => {
        const dateLimiteCommande = params.row.DateLimiteCommande;
        if (!dateLimiteCommande.Valid) {
          return "";
        }
        return dayjs(dateLimiteCommande.Time).format("DD/MM/YYYY");
      },
    },
    {
      field: "EtatLivraison",
      headerName: "État Livraison",
      headerClassName: styles.headerBgColor,
      width: 180,
      renderHeader: (params) => (
        <div style={{ fontWeight: "bold" }}>{params.colDef.headerName}</div>
      ),
      valueGetter: (params) => {
        const EtatLivraison = params.row.EtatLivraison;
        if (EtatLivraison == null) {
          return "Inconnu";
        } else {
          switch (EtatLivraison) {
            case 0:
              return "À venir";
              break;
            case 1:
              return "En cours";
              break;
            case 2:
              return "Livrée";
              break;
            default:
              return "";
              break;
          }
        }
      },
    },
    {
      field: "DateRemiseFichier",
      headerName: "Date Remise Fichiers",
      headerClassName: styles.headerBgColor,
      width: 180,
      renderHeader: (params) => (
        <div style={{ fontWeight: "bold" }}>{params.colDef.headerName}</div>
      ),
      valueGetter: (params) => {
        const dateRemiseFichier = params.row.DateRemiseFichier;
        if (!dateRemiseFichier.Valid) {
          return "";
        }
        return dayjs(dateRemiseFichier.Time).format("DD/MM/YYYY");
      },
    },
    {
      field: "DateLivraisonDebut",
      headerName: "Date Début Livraison",
      headerClassName: styles.headerBgColor,
      width: 180,
      renderHeader: (params) => (
        <div style={{ fontWeight: "bold" }}>{params.colDef.headerName}</div>
      ),
      valueGetter: (params) => {
        const dateLivraisonDebut = params.row.DateLivraisonDebut;
        if (!dateLivraisonDebut.Valid) {
          return "";
        }
        return dayjs(dateLivraisonDebut.Time).format("DD/MM/YYYY");
      },
    },
    {
      field: "DateLivraisonFin",
      headerName: "Date Fin Livraison",
      headerClassName: styles.headerBgColor,
      width: 180,
      renderHeader: (params) => (
        <div style={{ fontWeight: "bold" }}>{params.colDef.headerName}</div>
      ),
      valueGetter: (params) => {
        const dateLivraisonFin = params.row.DateLivraisonFin;
        if (!dateLivraisonFin.Valid) {
          return "";
        }
        return dayjs(dateLivraisonFin.Time).format("DD/MM/YYYY");
      },
    },
    {
      field: "DateDebutQuantiteApproximative",
      headerName: "Date Début Qtés Approx",
      headerClassName: styles.headerBgColor,
      width: 200,
      renderHeader: (params) => (
        <div style={{ fontWeight: "bold" }}>{params.colDef.headerName}</div>
      ),
      valueGetter: (params) => {
        const dateDebutQuantiteApproximative =
          params.row.DateDebutQuantiteApproximative;
        if (dateDebutQuantiteApproximative == null) {
          return "";
        }
        return dayjs(dateDebutQuantiteApproximative.Time).format("DD/MM/YYYY");
      },
    },
    {
      field: "DateFinQuantiteApproximative",
      headerName: "Date Fin Qtés Approx",
      headerClassName: styles.headerBgColor,
      width: 180,
      renderHeader: (params) => (
        <div style={{ fontWeight: "bold" }}>{params.colDef.headerName}</div>
      ),
      valueGetter: (params) => {
        const dateFinQuantiteApproximative =
          params.row.DateFinQuantiteApproximative;
        if (dateFinQuantiteApproximative == null) {
          return "";
        }
        return dayjs(dateFinQuantiteApproximative.Time).format("DD/MM/YYYY");
      },
    },
    {
      field: "DateDebutQuantiteDefinitive",
      headerName: "Date Début Qtés Def",
      headerClassName: styles.headerBgColor,
      width: 180,
      renderHeader: (params) => (
        <div style={{ fontWeight: "bold" }}>{params.colDef.headerName}</div>
      ),
      valueGetter: (params) => {
        const dateDebutQuantiteDefinitive =
          params.row.DateDebutQuantiteDefinitive;
        if (dateDebutQuantiteDefinitive == null) {
          return "";
        }
        return dayjs(dateDebutQuantiteDefinitive.Time).format("DD/MM/YYYY");
      },
    },
    {
      field: "DateFinQuantiteDefinitive",
      headerName: "Date Fin Qtés Def",
      headerClassName: styles.headerBgColor,
      width: 180,
      renderHeader: (params) => (
        <div style={{ fontWeight: "bold" }}>{params.colDef.headerName}</div>
      ),
      valueGetter: (params) => {
        const dateFinQuantiteDefinitive = params.row.DateFinQuantiteDefinitive;
        if (dateFinQuantiteDefinitive == null) {
          return "";
        }
        return dayjs(dateFinQuantiteDefinitive.Time).format("DD/MM/YYYY");
      },
    },
    {
      field: "NbPages",
      headerName: "Pages",
      headerClassName: styles.headerBgColor,
      width: 110,
      renderHeader: (params) => (
        <div style={{ fontWeight: "bold" }}>{params.colDef.headerName}</div>
      ),
    },
    {
      field: "NoReference",
      headerName: "Ref Nanotera",
      headerClassName: styles.headerBgColor,
      width: 140,
      renderHeader: (params) => (
        <div style={{ fontWeight: "bold" }}>{params.colDef.headerName}</div>
      ),
    },
    {
      field: "Budget",
      headerName: "Budget",
      headerClassName: styles.headerBgColor,
      width: 140,
      renderHeader: (params) => (
        <div style={{ fontWeight: "bold" }}>{params.colDef.headerName}</div>
      ),
      renderCell: (params) => <CloseIcon style={{ color: "red" }} />,
    },
    {
      field: "FichePlanning",
      headerName: "Fiche planning",
      headerClassName: styles.headerBgColor,
      width: 140,
      renderHeader: (params) => (
        <div style={{ fontWeight: "bold" }}>{params.colDef.headerName}</div>
      ),
      renderCell: (params) => <CloseIcon style={{ color: "red" }} />,
    },
    {
      field: "FicheTechnique",
      headerName: "Fiche technique",
      headerClassName: styles.headerBgColor,
      width: 140,
      renderHeader: (params) => (
        <div style={{ fontWeight: "bold" }}>{params.colDef.headerName}</div>
      ),
      renderCell: (params) => <CloseIcon style={{ color: "red" }} />,
    },
    {
      field: "Facture",
      headerName: "Facture",
      headerClassName: styles.headerBgColor,
      width: 140,
      renderHeader: (params) => (
        <div style={{ fontWeight: "bold" }}>{params.colDef.headerName}</div>
      ),
      renderCell: (params) => <CloseIcon style={{ color: "red" }} />,
    },
  ];

  interface MyObject {
    Libelle: any;
    Semestre: any;
    ValiditeDu: any;
    ValiditeAu: any;
    NoReference: any;
    DateRemiseFichier: any;
    DateLivraisonDebut: any;
    DateLivraisonFin: any;
    DateLimiteCommande: any;
    NbPages: any;
    noDevis: any;
    Pagination: any;
    Tirage: any;
    LargeurFini: any;
    HauteurFini: any;
    Largeur: any;
    Hauteur: any;
    Grammage: any;
    Finition: any;
    Faconnage: any;
    TotalPA_Marge: any;
    Facture: any;
    CU: any;
    CU_Facture: any;
  }

  const [apiUrl, setApiUrl] = useState("");
  const [rechercheOp, setRechercheOp] = React.useState("");
  const [rechercheDoc, setRechercheDoc] = React.useState("");
  const [trimestre, setTrimestre] = React.useState("");
  const [annee, setAnnee] = React.useState<any>(new Date().getFullYear());
  const [rowss, setRowss] = React.useState([]);
  const [myObject, setMyObject] = React.useState<MyObject>();

  function openModal(id: any) {
    var obj = rowss[id - 1];
    parentToChild(obj);
  }

  const parentToChild = async (rowObj: any) => {
    var NoDevis = rowObj.NoDevis;
    if (NoDevis.Valid === true) {
      NoDevis = NoDevis.Int64;
    }
    var NoDevisCom = rowObj.NoDevisCom;
    var NoRef = rowObj.NoReference;
    var detailsObj = await getDataDetails(NoDevis, NoDevisCom, NoRef);

    var fusedObj = {
      ...(rowObj as any),
      ...(detailsObj as any),
    };
    setMyObject(fusedObj);
  };

  function createDateFilter() {
    const a = annee;
    const t = trimestre;
    var filter = {} as any;
    switch (t) {
      case "1": {
        filter.From = `${a}-01-01T00:00:00`;
        filter.To = `${a}-03-31T23:59:59`;
        break;
      }
      case "2": {
        filter.From = `${a}-04-01T00:00:00`;
        filter.To = `${a}-06-30T23:59:59`;
        break;
      }
      case "3": {
        filter.From = `${a}-07-01T00:00:00`;
        filter.To = `${a}-09-30T23:59:59`;
        break;
      }
      case "4": {
        filter.From = `${a}-10-01T00:00:00`;
        filter.To = `${a}-12-31T23:59:59`;
        break;
      }
      default: {
        filter.From = `${a}-01-01T00:00:00`;
        filter.To = `${a}-12-31T23:59:59`;
        break;
      }
    }
    getDataWithFilter(filter);
  }

  const handleChangeRechercheOp = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRechercheOp(e.target.value);
  };

  const handleChangeRechercheDoc = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRechercheDoc(e.target.value);
  };

  const handleChangeTrimestre = (event: SelectChangeEvent) => {
    setTrimestre(event.target.value as string);
  };

  const handleChangeAnnee = (event: SelectChangeEvent) => {
    setAnnee(event.target.value as string);
  };

  const getData = async (): Promise<void> => {
    const data = await getDataOperations();
    if (data) {
      FillFakeData(data);
      setRowss(data);
    } else {
      setRowss([]);
    }
    setRowss(data);
  };

  const getDataWithFilter = async (filter: any): Promise<void> => {
    var data;
    if (rechercheDoc) {
      data = await getDataOperationsFilterByDoc(
        filter,
        rechercheOp,
        rechercheDoc
      );
    } else {
      data = await getDataOperationsFilter(filter, rechercheOp);
    }
    if (data) {
      FillFakeData(data);
      setRowss(data);
    } else {
      setRowss([]);
    }
  };

  const getDataDetails = async (
    noDevis: any,
    noDevisCom: any,
    noRef: any
  ): Promise<void> => {
    const data = await getDataDetailsTechniques(noDevis, noDevisCom, noRef);
    return data;
  };

  function closeDetails() {
    setMyObject(undefined);
  }

  function logDoubleClick() {
    console.log("doubleclick");
  }

  function FillFakeData(data: any) {
    data.forEach(
      (element: {
        Budget: string;
        FichePlanning: string;
        FicheTechnique: string;
        Facture: string;
        id: any;
        ID: any;
      }) => {
        element.id = element.ID;
        element.Budget = "X";
        element.FichePlanning = "X";
        element.FicheTechnique = "X";
        element.Facture = "X";
      }
    );
    return data;
  }

  useEffect(() => {
    getApiUrl()
      .then(async (apiUrl) => {
        if (apiUrl) {
          await setApiUrl(apiUrl);
          getData();
        }
      })
      .catch((error) => {
        // Gérez l'erreur ici si nécessaire
        console.error(
          "Erreur lors de la récupération de l'URL de l'API :",
          error
        );
      });
  }, [apiUrl]);

  return (
    <div className={styles.mainPage}>
      <HeaderComponent />

      <div className={styles.pageContainer}>
        {/* container 1  */}
        <div
          className={`${styles.mainContainer} ${
            myObject ? styles.mainContainerSmall : styles.mainContainer
          }`}
        >
          {/* Titre */}
          <div className={styles.title}>
            {/* <div className={styles.marginRight}>Gestion du</div> */}
            <div className={styles.bold}>Vos opérations</div>
          </div>

          {/* Filtres et recherche */}
          <div>
            <div
              className={`${styles.bold} ${styles.fontColor} ${styles.mBottom}`}
            >
              Rechercher une opération
            </div>
            <Stack spacing={2} direction="row" className={styles.mBottom}>
              <FormControl>
                <InputLabel id="trimestre-input-label">Trimestre</InputLabel>
                <Select
                  labelId="trimestre-label"
                  id="trimestre"
                  value={trimestre}
                  label="Trimestre"
                  className={styles.select}
                  onChange={handleChangeTrimestre}
                >
                  <MenuItem value={"1"}>Janv/Mars</MenuItem>
                  <MenuItem value={"2"}>Avr/Juin</MenuItem>
                  <MenuItem value={"3"}>Juil/Sept</MenuItem>
                  <MenuItem value={"4"}>Oct/Déc</MenuItem>
                  <MenuItem value={"5"}>Tous</MenuItem>
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel id="annee-input-label">Année</InputLabel>
                <Select
                  labelId="annee-label"
                  id="annee"
                  value={annee}
                  label="Année"
                  className={styles.select}
                  onChange={handleChangeAnnee}
                >
                  <MenuItem value={2014}>2014</MenuItem>
                  <MenuItem value={2015}>2015</MenuItem>
                  <MenuItem value={2016}>2016</MenuItem>
                  <MenuItem value={2017}>2017</MenuItem>
                  <MenuItem value={2018}>2018</MenuItem>
                  <MenuItem value={2019}>2019</MenuItem>
                  <MenuItem value={2020}>2020</MenuItem>
                  <MenuItem value={2021}>2021</MenuItem>
                  <MenuItem value={2022}>2022</MenuItem>
                  <MenuItem value={2023}>2023</MenuItem>
                  <MenuItem value={2024}>2024</MenuItem>
                  <MenuItem value={2025}>2025</MenuItem>
                  <MenuItem value={2026}>2026</MenuItem>
                </Select>
              </FormControl>
              <FormControl>
                <TextField
                  id="outlined-basic"
                  label="Rechercher par opération"
                  variant="outlined"
                  value={rechercheOp}
                  onChange={handleChangeRechercheOp}
                />
              </FormControl>
              <FormControl>
                <TextField
                  id="outlined-basic"
                  label="Rechercher par support"
                  variant="outlined"
                  value={rechercheDoc}
                  onChange={handleChangeRechercheDoc}
                />
              </FormControl>
              <Button
                variant="contained"
                size="large"
                style={{ height: 56, fontSize: 20 }}
                onClick={createDateFilter}
              >
                Go !
              </Button>
            </Stack>
          </div>

          {/* Table */}
          <div>
            <ThemeProvider theme={theme}>
              <DataGrid
                rows={rowss}
                columns={columnss}
                className={`${styles.withRows} ${
                  rowss.length > 0 ? styles.withRows : styles.withoutRows
                }`}
                disableRowSelectionOnClick
                slots={{
                  noRowsOverlay: CustomNoRowsOverlay,
                  toolbar: CustomToolbar,
                }}
                onRowDoubleClick={logDoubleClick}
              />
            </ThemeProvider>
          </div>
        </div>
        {/* container 2 */}
        {myObject && (
          <div className={styles.subContainer}>
            <DetailsComponent myObject={myObject} onDelete={closeDetails} />
          </div>
        )}
      </div>
    </div>
  );
}

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>Pas de résultats</Box>
    </StyledGridOverlay>
  );
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}
